import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  LicenseCreateRequest,
  LicenseCreateResponse,
  LicenseListResponse,
  LicenseOneRequest,
  LicenseOneResponse,
  LicenseUpdateRequest,
  LicenseUpdateResponse,
} from './types';


export class LicenseApi {
  public static async create(
    payload: LicenseCreateRequest,
  ): Promise<BaseResponseInterface<LicenseCreateResponse>> {
    return await HttpService.sendRequest('POST', '/license/create', payload);
  };

  public static async list(
  ): Promise<BaseResponseInterface<LicenseListResponse>> {
    return await HttpService.sendRequest('GET', '/license/list');
  };

  public static async one(
    payload: LicenseOneRequest,
  ): Promise<BaseResponseInterface<LicenseOneResponse>> {
    return await HttpService.sendRequest('GET', '/license/one', payload);
  };

  public static async update(
    payload: LicenseUpdateRequest,
  ): Promise<BaseResponseInterface<LicenseUpdateResponse>> {
    return await HttpService.sendRequest('POST', '/license/update', payload);
  };
};
