import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  AnonGuardHoc,
  AuthGuardHoc,
  HomePage,
  LicenseCreatePage,
  LicenseListPage,
  LicenseOnePage,
  NotFoundPage,
  RoleCreatePage,
  RoleListPage,
  RoleOnePage,
  SettingsPage,
  SigninPage,
  UserCreatePage,
  UserListPage,
  UserOnePage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><HomePage /></AuthGuardHoc> },
    { path: '/license-list', element: <AuthGuardHoc><LicenseListPage /></AuthGuardHoc> },
    { path: '/license-list/create', element: <AuthGuardHoc><LicenseCreatePage /></AuthGuardHoc> },
    { path: '/license-list/:id', element: <AuthGuardHoc><LicenseOnePage /></AuthGuardHoc> },
    { path: '/role-list/create', element: <AuthGuardHoc><RoleCreatePage /></AuthGuardHoc> },
    { path: '/role-list/:id', element: <AuthGuardHoc><RoleOnePage /></AuthGuardHoc> },
    { path: '/role-list', element: <AuthGuardHoc><RoleListPage /></AuthGuardHoc> },
    { path: '/settings', element: <AuthGuardHoc><SettingsPage /></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/user-list/create', element: <AuthGuardHoc><UserCreatePage /></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><UserOnePage /></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><UserListPage /></AuthGuardHoc> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};