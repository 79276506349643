import { useNavigate } from 'react-router-dom';

import {
  WidgetLinkBrick,
  AdminGuardIcon,
  ManageAccountsIcon,
  WidgetsListBlock,
} from '@app/components';


export const HomeFeature = () => {
  const navigate = useNavigate();

  return (
    <WidgetsListBlock>
      <WidgetLinkBrick
        title="Роли"
        onClick={() => navigate('/role-list')}
        icon={<AdminGuardIcon />}
      />
      <WidgetLinkBrick
        title="Пользователи"
        onClick={() => navigate('/user-list')}
        icon={<ManageAccountsIcon />}
      />
      <WidgetLinkBrick
        title="Лицензии"
        onClick={() => navigate('/license-list')}
        icon={<ManageAccountsIcon />}
      />
    </WidgetsListBlock>
  );
};
