import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
} from '@app/components';
import { useMainContext } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    licenseId,
    licenseOneIsLoading,
    licenseGeneralIsEditing,
    licenseGeneralFormOpen,
    licenseGeneralFormClose,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Лицензия №' + licenseId}
      extra={
        <>
          {!licenseGeneralIsEditing && <ButtonEditBlock onClick={() => licenseGeneralFormOpen()} />}
          {!licenseGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {licenseGeneralIsEditing && <ButtonCancelBlock onClick={() => licenseGeneralFormClose()} buttonIcon />}
        </>
      }
      loading={licenseOneIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
