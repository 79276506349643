import { create } from 'zustand';

import { BreadcrumbType } from '@app/types';


export interface BreadcrumbsStoreInterface {
  breadcrumbs: BreadcrumbType[];
  breadcrumbsHomeSet: () => void;
  breadcrumbsSettingsSet: () => void;
  breadcrumbsRoleListSet: () => void;
  breadcrumbsRoleOneSet: (id: number) => void;
  breadcrumbsRoleCreateSet: () => void;
  breadcrumbsUserListSet: () => void;
  breadcrumbsUserOneSet: (id: number) => void;
  breadcrumbsUserCreateSet: () => void;
  breadcrumbsLicenseListSet: () => void;
  breadcrumbsLicenseCreateSet: () => void;
  breadcrumbsLicenseOneSet: (id: number) => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStoreInterface>((set) => ({
  breadcrumbs: [],
  breadcrumbsHomeSet: () => set(() => ({
    breadcrumbs: [{ title: 'Главная', link: '/' }],
  })),
  breadcrumbsSettingsSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Настройки', link: '/' },
    ],
  })),
  breadcrumbsRoleListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/' },
    ],
  })),
  breadcrumbsRoleOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Роль №' + id, link: '/' },
    ],
  })),
  breadcrumbsRoleCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Новая роль', link: '/' },
    ],
  })),
  breadcrumbsUserListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/' },
    ],
  })),
  breadcrumbsUserOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '/' },
    ],
  })),
  breadcrumbsUserCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Новый пользователь', link: '/' },
    ],
  })),
  breadcrumbsLicenseListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Лицензии', link: '/license-list' },
    ],
  })),
  breadcrumbsLicenseCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Лицензии', link: '/license-list' },
      { title: 'Новая лицензия', link: '/license-create' },
    ],
  })),
  breadcrumbsLicenseOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Лицензии', link: '/license-list' },
      { title: 'Лицензия №' + id, link: '/' },
    ],
  })),
}));
