import React from 'react';

import { LicenseListFeature, PageBaseLayout } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const LicenseListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsLicenseListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsLicenseListSet();
  }, [
    breadcrumbsLicenseListSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <LicenseListFeature />
    </PageBaseLayout>
  );
};
