import {
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldDateBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext } from '../../context';

export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    errorSet,
  } = useMainContext();

  return (
    <CardDataComposition
      title={'Новая лицензия'}
      extra={<ButtonBackBlock buttonIcon />}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          label="Название"
          name="name"
          error={errorSet('name')}
          onChange={changeFormValue}
          value={formValue.name}
          required
        />
        <FieldDateBlock
          label="Дата отключения"
          name="expirationDate"
          error={errorSet('expirationDate')}
          onChange={changeFormValue}
          value={formValue.expirationDate}
          required
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
};
