import { UpdateProvider } from '../../context';
import { GeneralComponent } from '../general';
import { GeneralTableComponent } from '../general-table';
import { GeneralUpdateComponent } from '../general-update';

export const MainComponent = () => {

  return (
    <GeneralComponent>
      <GeneralTableComponent />
      <UpdateProvider>
        <GeneralUpdateComponent />
      </UpdateProvider>
    </GeneralComponent>
  );
};
