import React from 'react';
import { useParams } from 'react-router-dom';

import { LicenseType } from '@app/types';
import { LicenseApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  licenseId: number | null;
  licenseOne: LicenseType | null;
  licenseOneIsLoading: boolean;
  licenseGeneralIsEditing: boolean;
  licenseOneSet: React.Dispatch<React.SetStateAction<LicenseType | null>>;
  licenseGeneralFormOpen: () => void;
  licenseGeneralFormClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const [ licenseOne, licenseOneSet ] = React.useState<LicenseType | null>(null);
  const [ licenseOneIsLoading, licenseOneIsLoadingSet ] = React.useState(false);
  const [ licenseGeneralIsEditing, licenseGeneralIsEditingSet ] = React.useState(false);

  const licenseId = React.useMemo(() => {
    if (!id || isNaN(Number(id))) return null;
    return Number(id);
  }, [id]);

  const licenseOneDownload = React.useCallback(async () => {
    if (licenseId === null) return;

    licenseOneIsLoadingSet(true);
    const response = await LicenseApi.one({ id: licenseId });
    licenseOneIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке лицензии.'
      });
    }

    licenseOneSet(response.data.license);
  }, [
    licenseId,
    notifyCall,
  ]);
  
  const licenseGeneralFormOpen = React.useCallback(() => {
    licenseGeneralIsEditingSet(true);
  }, []);

  const licenseGeneralFormClose = React.useCallback(() => {
    licenseGeneralIsEditingSet(false);
  }, []);

  React.useEffect(() => {
    licenseOneDownload();
  }, [licenseOneDownload]);

  return React.useMemo(() => ({
    licenseId,
    licenseOne,
    licenseOneIsLoading,
    licenseGeneralIsEditing,
    licenseGeneralFormOpen,
    licenseGeneralFormClose,
    licenseOneSet,
  }), [
    licenseId,
    licenseOne,
    licenseOneIsLoading,
    licenseGeneralIsEditing,
    licenseGeneralFormOpen,
    licenseGeneralFormClose,
    licenseOneSet,
  ]);
};
