import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldDateBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import { useMainContext, useUpdateContext } from '../../context';


export const GeneralUpdateComponent = () => {
  const {
    licenseGeneralIsEditing,
    licenseGeneralFormClose,
  } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSumbitting,
    onSubmit,
    errorSet,
    changeFormValue,
  } = useUpdateContext();

  if (formValue === null || !licenseGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSumbitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={licenseGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        label="Название"
        name="name"
        error={errorSet('name')}
        onChange={changeFormValue}
        value={formValue.name}
        required
      />
      <FieldDateBlock
        label="Дата отключения"
        name="expirationDate"
        error={errorSet('expirationDate')}
        onChange={changeFormValue}
        value={formValue.expirationDate}
        required
      />
    </FormCenteredBlock>
  );
};
