import React from 'react';
import { LicenseType } from '@app/types';
import { LicenseApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  licenseList: LicenseType[];
  licenseListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const { notifyCall } = useAppStore();
  const [ licenseList, licenseListSet ] = React.useState<LicenseType[]>([]);
  const [ licenseListIsLoading, licenseListIsLoadingSet ] = React.useState(false);

  const licenseListDownload = React.useCallback(async () => {
    licenseListIsLoadingSet(true);
    const response = await LicenseApi.list();
    licenseListIsLoadingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Произошла ошибка при загрузке пользователей.'
      });
    }

    licenseListSet(response.data.licenseList);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    licenseListDownload();
  }, [
    licenseListDownload,
  ]);

  return React.useMemo(() => ({
    licenseList,
    licenseListIsLoading,
  }), [
    licenseList,
    licenseListIsLoading,
  ]);
};
