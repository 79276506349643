import {
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';
import { translateDateHelper } from '@app/helpers';
import { useMainContext } from '../../context';

export const GeneralTableComponent = () => {
  const {
    licenseOne,
    licenseGeneralIsEditing,
  } = useMainContext();
  if (licenseOne === null || licenseGeneralIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="licenses">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Название</TableCellBrick>
            <TableCellBrick>Ключ</TableCellBrick>
            <TableCellBrick>Дата отключения</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
            <TableCellBrick>Дата последнего рефреша</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={licenseOne.id}
          >
            <TableCellBrick component="th" scope="row">
              {licenseOne.id}
            </TableCellBrick>
            <TableCellBrick>{licenseOne.name}</TableCellBrick>
            <TableCellBrick>{licenseOne.key}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(licenseOne.expirationDate)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(licenseOne.createdAt)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(licenseOne.refreshDate)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
