import React from 'react';

import { PageBaseLayout, LicenseCreateFeature } from '@app/components'
import { useBreadcrumbsStore } from '@app/stores';


export const LicenseCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsLicenseCreateSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsLicenseCreateSet();
  }, [
    breadcrumbsLicenseCreateSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <LicenseCreateFeature />
    </PageBaseLayout>
  );
};
